import { ReactElement } from 'react'
import { Box, Button } from '@mui/material'

interface IAppButton {
  label?: string
  type?: 'button' | 'submit'
  onClick?: () => void
}

const AppButton = ({ label = 'Enviar', type = 'button', onClick }: IAppButton): ReactElement => {
  return (
    <Box sx={{ py: '7px' }}>
      <Button
        type={type}
        size='large'
        color='primary'
        variant='contained'
        fullWidth={true}
        onClick={onClick}
      >{label}
      </Button>
    </Box>
  )
}

export default AppButton
