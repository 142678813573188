import { ReactElement } from 'react'

const Home = (): ReactElement => {
  return (
    <div>
      Software de control de accesos
    </div>
  )
}

export default Home
