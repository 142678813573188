export const signInError = (error: any, failStack: (message: string) => void): void => {
  switch (error.code) {
    case 'auth/wrong-password':
      failStack('La contraseña no es correcta')
      break
    case 'auth/user-not-found':
      failStack('No existe ninguna cuenta con este correo')
      break
    default:
      failStack('Hubo un error al intentar iniciar sesión la cuenta')
      break
  }
}

export const signUpError = (error: any, failStack: (message: string) => void): void => {
  switch (error.code) {
    case 'auth/invalid-password':
      failStack('La contraseña tiene que ser de al menos 6 caracteres')
      break
    case 'auth/email-already-in-use':
      failStack('Ya existe una cuenta con el correo electrónico proporcionado')
      break
    case 'auth/invalid-email':
      failStack('El correo electrónico no es válido')
      break
    default:
      failStack('Hubo un error al intentar crear la cuenta')
      break
  }
}
