import { FormikProps } from 'formik'
import { useState, ReactElement } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Box, TextField, IconButton, InputAdornment } from '@mui/material'

interface IAppInput {
  name: string
  label: string
  formik: FormikProps<any>
  type?: string
}

const AppInput = ({ name, label, formik, type = 'text' }: IAppInput): ReactElement => {
  const [buttonToggle, setButtonToggle] = useState(false)

  const toggleButton = (): void => {
    setButtonToggle((previousValue) => !previousValue)
  }

  return (
    <Box sx={{ py: '7px' }}>
      <TextField
        fullWidth
        name={name}
        label={label}
        value={formik.values[name]}
        onChange={formik.handleChange}
        type={buttonToggle ? 'text' : type}
        error={formik.touched[name] === true && Boolean(formik.errors[name])}
        helperText={formik.touched[name] === true && formik.errors[name]}
        InputProps={{
          endAdornment: type === 'password' ? (
            <InputAdornment position='end'>
              <IconButton onClick={toggleButton} edge='end'>
                {buttonToggle ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : null
        }}
      />
    </Box>
  )
}

export default AppInput
