import { useFormik } from 'formik'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { createUserWithEmailAndPassword } from 'firebase/auth'

// Project
import { useGlobalContext } from '../context'
import { auth } from '../utils/firebaseConfig'
import { signUpError } from '../utils/errorHandler'
import { signUpSchema } from '../utils/yupValidations'

// Components
import AppInput from '../components/AppInput'
import AppButton from '../components/AppButton'
import AppHeading from '../components/AppHeading'

const SignUp = (): ReactElement => {
  const navigate = useNavigate()
  const { failStack } = useGlobalContext()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      passwordRepeat: ''
    },
    validationSchema: signUpSchema,
    onSubmit: async (values) => {
      try {
        await createUserWithEmailAndPassword(auth, values.email, values.password)
        navigate('/')
      } catch (error: any) {
        signUpError(error, failStack)
      }
    }
  })

  return (
    <>
      <AppHeading title='Registrarse'>
        <AppButton label='Iniciar sesión' />
      </AppHeading>
      <form onSubmit={formik.handleSubmit}>
        <AppInput name='email' formik={formik} label='Correo' />
        <AppInput name='password' formik={formik} label='Contraseña' />
        <AppInput name='passwordRepeat' formik={formik} label='Repetir contraseña' />
        <AppButton type='submit' />
      </form>
    </>
  )
}

export default SignUp
