import { Box, SvgIcon } from '@mui/material'
import { ReactNode, ReactElement } from 'react'

const Wrapper = ({ children }: { children: ReactNode }): ReactElement => {
  return (
    <>
      <Box sx={{
        width: '90%',
        zIndex: '100',
        margin: 'auto',
        display: 'flex',
        padding: '10px',
        maxWidth: '70rem',
        overflowY: 'auto',
        position: 'relative',
        borderRadius: '10px',
        background: '#ffffff',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: { xs: '95vh', md: '90vh' },
        maxHeight: { xs: 'none', md: '50rem' },
        boxShadow: '0px 1.25rem 2.5rem rgba(0, 0, 0, 0.05)'
      }}
      >
        {children}
      </Box>
      <SvgIcon
        viewBox='0 0 1440 320' preserveAspectRatio='none' sx={{
          bottom: '0',
          zIndex: '0',
          width: '100%',
          height: '50vh',
          position: 'fixed',
          '& path': {
            fillOpacity: 1,
            fill: 'rgba(135, 182, 194, 0.15)'
          }
        }}
      >
        <path d='M0,64L80,96C160,128,320,192,480,202.7C640,213,800,171,960,160C1120,149,1280,171,1360,181.3L1440,192L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z' />
      </SvgIcon>
    </>
  )
}

export default Wrapper
