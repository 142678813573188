import './index.css'
import { render } from 'react-dom'
import { load } from 'webfontloader'
import { ReactElement } from 'react'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

// Project
import { GlobalProvider } from './context'

// Pages
import Home from './pages/Home'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'

load({
  google: {
    families: ['Work Sans:400,500,700', 'sans-serif']
  }
})

const Index = (): ReactElement => {
  return (
    <SnackbarProvider maxSnack={5}>
      <BrowserRouter>
        <GlobalProvider>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='signin' element={<SignIn />} />
            <Route path='signup' element={<SignUp />} />
            <Route path='*' element={<Navigate to="signin" />} />
          </Routes>
        </GlobalProvider>
      </BrowserRouter>
    </SnackbarProvider>
  )
}

render(<Index />, document.getElementById('root'))
