import Wrapper from './Wrapper'
import { useSnackbar } from 'notistack'
import { auth } from '../utils/firebaseConfig'
import { User as FirebaseUser, onAuthStateChanged } from 'firebase/auth'
import { useState, useEffect, useContext, createContext, ReactElement } from 'react'

interface IGlobalContext {
  fireUser: FirebaseUser | null
  niceStack: (message: string) => void
  failStack: (message: string) => void
}

const GlobalContext = createContext<IGlobalContext | undefined>(undefined) // Create context

export const useGlobalContext = (): IGlobalContext => { // Hook to get context
  const context: IGlobalContext | undefined = useContext(GlobalContext)
  if (context === undefined) throw Error('Context is not working properly')
  return context
}

export const GlobalProvider = ({ children }: { children: ReactElement }): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const [fireUser, setUsuario] = useState<FirebaseUser | null>(null)
  const [loading, setLoading] = useState(true) // Determine when onAuthStateChanged finishes loading

  useEffect(() => {
    onAuthStateChanged(auth, (firebaseUser) => { // Validate if user is logged in
      setUsuario(firebaseUser ?? null)
      setLoading(false)
    })
  }, [])

  const niceStack = (message: string): void => {
    enqueueSnackbar(message, { variant: 'success' })
  }

  const failStack = (message: string): void => {
    enqueueSnackbar(message, { variant: 'error' })
  }

  return (
    <GlobalContext.Provider value={{ fireUser, niceStack, failStack }}>
      {
        !loading && ( // Wait until user loads before loading UI
          <Wrapper>{children}</Wrapper>
        )
      }
    </GlobalContext.Provider>
  )
}
