import * as yup from 'yup'

const emailText = 'Debe ingresar un correo válido'
const requiredText = 'Este campo es obligatorio'
const passwordText = 'La contraseña debe ser mayor a 8 carácteres'
const passwordMatchText = 'Las contraseñas no coinciden'

export const signInSchema = yup.object({
  email: yup.string().required(requiredText).email(emailText),
  password: yup.string().required(requiredText).min(8, passwordText)
})

export const signUpSchema = yup.object({
  email: yup.string().required(requiredText).email(emailText),
  password: yup.string().required(requiredText).min(8, passwordText),
  passwordRepeat: yup.string().required(requiredText).oneOf([yup.ref('password'), null], passwordMatchText)
})
