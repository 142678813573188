import { useFormik } from 'formik'
import { ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { signInWithEmailAndPassword } from 'firebase/auth'

// Project
import { useGlobalContext } from '../context'
import { auth } from '../utils/firebaseConfig'
import { signInError } from '../utils/errorHandler'
import { signInSchema } from '../utils/yupValidations'

// Components
import AppInput from '../components/AppInput'
import AppButton from '../components/AppButton'
import AppHeading from '../components/AppHeading'

const SignIn = (): ReactElement => {
  const navigate = useNavigate()
  const { failStack } = useGlobalContext()

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: signInSchema,
    onSubmit: async (values) => {
      try {
        await signInWithEmailAndPassword(auth, values.email, values.password)
        navigate('/')
      } catch (error: any) {
        signInError(error, failStack)
      }
    }
  })

  return (
    <>
      <AppHeading title='Iniciar sesión'>
        <AppButton label='Registrarse' />
      </AppHeading>
      <form onSubmit={formik.handleSubmit}>
        <AppInput name='email' formik={formik} label='Correo' />
        <AppInput name='password' formik={formik} label='Contraseña' type='password' />
        <AppButton type='submit' />
      </form>
    </>
  )
}

export default SignIn
