import { ReactNode, ReactElement } from 'react'
import { Box, Grid, Typography } from '@mui/material'

interface IAppHeading {
  title: string
  children: ReactNode
}

const AppHeading = ({ title, children }: IAppHeading): ReactElement => {
  return (
    <>
      <Box sx={{ padding: '20px' }}>
        <Grid container spacing={2}>
          <Grid
            item xs={12} sm={7} sx={{
              textAlign: { xs: 'center', sm: 'left' },
              alignItems: { xs: 'center', sm: 'left' }
            }}
          >
            <Typography variant='h3'>{title}</Typography>
          </Grid>
          <Grid
            item xs={12} sm={5} sx={{
              textAlign: { xs: 'center', sm: 'right' },
              alignItems: { xs: 'center', sm: 'right' }
            }}
          >
            {children}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{
        textAlign: 'center',
        alignItems: 'center',
        paddingTop: '25px',
        paddingBottom: '25px',
        '& img': {
          width: { xs: '200px', md: '250px', xl: '300px' },
          maxWidth: '75%'
        }
      }}
      >
        <img src='https://s3.marroquin.dev/luismx/inventario.png' alt='not-found' />
      </Box>
    </>
  )
}

export default AppHeading
